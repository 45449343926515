.cred-sec-blog-post-card {
  width: auto;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 4px 4px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.cred-sec-blog-post-card:hover {
  transform: scale(1.02);
}
.cred-sec-container {
  display: flex;
  box-shadow: 5px 5px 10px 0px #424040;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #3c3a3a;
}
.cred-sec-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: space-between;
}
.cred-sec-text {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-weight: 600;
  text-transform: uppercase;
}
.cred-sec-text1 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-weight: 300;
  margin-left: var(--dl-space-space-threeunits);
}
.cred-sec-text2 {
  fill: var(--dl-color-gray-white);
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-twounits);
}
.cred-sec-text3 {
  fill: var(--dl-color-gray-900);
  color: var(--dl-color-gray-900);
  font-size: 1.25rem;
  margin-bottom: var(--dl-space-space-twounits);
}





















@media(max-width: 991px) {
  .cred-sec-blog-post-card {
    flex-direction: row;
    justify-content: space-between;
  }
}
@media(max-width: 767px) {
  .cred-sec-blog-post-card {
    flex-direction: column;
  }
}
