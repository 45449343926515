.user-home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #181717;
}
.user-home-header {
  width: 100%;
  display: flex;
  max-width: auto;
  box-shadow: 5px 5px 10px 0px #2d2b2b;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #2d2b2b;
}
.user-home-image {
  width: 500px;
  height: 100px;
  object-fit: cover;
}
.user-home-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: var(--dl-color-gray-900);
}
.user-home-cred-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  flex-wrap: wrap;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-home-container1 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
@media(max-width: 767px) {
  .user-home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .user-home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .user-home-cred-container {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .user-home-header {
    padding: var(--dl-space-space-unit);
  }
  .user-home-image {
    width: 450px;
  }
  .user-home-separator {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .user-home-cred-container {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
